.btn {
    font-family: "Inter", sans-serif;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 7px 23px;
    border: 0;
    min-width: 110px;
    &.close-btn {
        color: #4c5468;
        border-color: #4d556a;
        background-color: transparent;
        border-width: 1px;
        border-style: solid;
        &:hover {
            background-color: #fff2f0;
        }
        span {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &.submit-btn {
        background-color: #fcbb55;
        border: 0;
        color: #fff;
        transition-duration: .15s;
        &:hover {
            background-color: #f1ab39;
            color: #fff;
        }  
        span {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &.add-btn {
        background-color: #4d556a;
        padding: 7px 30px;
        border: 0;
        & > span {
            display: flex;
            align-items: center;
            gap: 3px;
        }
    }
}