@import './form.scss';
@import './button.scss';

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.h-50 {
    height: 50px !important;
}

.dashboard-wrapper {
    h5 {
        &.page-title {
            font-size: 1rem;
            color: #1e293b;
            // padding-bottom: 1rem;
            margin: 0;
            text-wrap: nowrap;

            .spinner-border {
                width: 10px;
                height: 10px;
            }

            .badge {
                // width: 25px;
            }
        }
    }

    .dashboard-card {
        border-radius: 0.375rem;
        border-width: 0px;
        border-color: transparent;
        background-color: rgb(255 255 255 / 1);
        box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000), var(#e2e8f0);
        // min-height: 80vh;
        // overflow: auto;
        // max-height: 80vh;
        // height: 100%;
        overflow-y: auto;
        max-height: calc(100vh - 140px);
        min-height: calc(100vh - 150px);
        position: relative;

        .dashboard-card-body {
            padding: 1.25rem;
            height: calc(100vh - 24vh);

            .table-responsive {
                border-radius: 0.375rem;
                overflow-x: auto;
                overflow-y: auto;
                max-height: calc(100vh - 36vh);
                min-height: calc(100vh - 36vh);
                height: 100%;

                // box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
                .table {
                    // box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
                    min-width: 1000px !important;
                    width: 100%;
                    background: #fff;
                    margin-bottom: 0;

                    &.nowrap {
                        text-wrap: nowrap;
                    }

                    thead {
                        position: sticky;
                        top: -0.1px;
                        z-index: 2;

                        tr {
                            th {
                                font-weight: 600;
                                font-size: 0.9rem;
                                color: #ffa000;
                                padding: 19px 20px;
                                border-bottom: 0;
                                background-color: #fff9f0;
                                text-align: center;
                            }
                        }
                    }

                    tbody {
                        tr {

                            td,
                            th {
                                padding: 19px 20px;
                                // vertical-align: middle;
                                line-height: 1.462;
                                font-size: .85rem;
                                font-weight: 500;
                                color: #333335;
                                vertical-align: middle;
                                border-bottom: 2px solid #f1f5f9;

                                .action-col {
                                    a {
                                        background-color: #e9f0f7;
                                        color: #4d556a;
                                        height: 35px;
                                        width: 35px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 0.375rem;
                                        font-size: 11px;
                                        transition-duration: .2s;
                                        cursor: pointer;

                                        svg {
                                            font-size: 15px;
                                        }

                                        &.edit:hover {
                                            color: #2d77ed;
                                            background-color: #cfe3fc;
                                        }

                                        &.delete:hover {
                                            color: #ef4444;
                                            background-color: #fee2e2;
                                        }

                                        &.view:hover {
                                            color: #2db33d;
                                            background-color: #d0fccf;
                                        }
                                    }
                                }

                                .assign-btn {
                                    padding: 6px 8px;
                                    background-color: #eff6ff;
                                    text-decoration: none;
                                    border-radius: 6px;
                                    color: #3b82f6;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 6px;
                                    cursor: pointer;
                                    font-size: 0.8rem;
                                }

                                .status-col {
                                    span {
                                        padding: 6px 14px;
                                        color: #202020;
                                        font-size: 13px;
                                        border-radius: 30px;

                                        &.completed {
                                            border: 1px solid #6eff4c;
                                            background: #d2ffd4;
                                        }

                                        &.submitted {
                                            background: #e0edfe;
                                            border: 1px solid #3ba1f9;
                                        }

                                        &.inprogress {
                                            border: 1px solid #fbb33a;
                                            background: #fff9f0;
                                        }
                                    }
                                }
                            }

                            .remarks {
                                a {
                                    padding: 6px 8px;
                                    background-color: #198754;
                                    text-decoration: none;
                                    border-radius: 6px;
                                    color: #ffffff;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 6px;
                                    cursor: pointer;
                                    font-size: 0.8rem;
                                }
                            }

                            th {
                                font-weight: 600;
                                font-size: 0.9rem;
                            }

                            .checkbox {
                                input {
                                    width: 45px;
                                }

                                .form-check-input:checked {
                                    background-color: #00d220;
                                    border-color: #00d220;
                                }
                            }
                        }
                    }
                }

                &.role-table {
                    border-radius: 0.375rem;
                    overflow-x: auto;
                    overflow-y: auto;
                    max-height: calc(100vh - 50vh);
                    min-height: calc(100vh - 50vh);
                    height: 100%;
                }

                &.export-table {
                    max-height: calc(100vh - 37vh);
                    min-height: calc(100vh - 37vh);
                }
            }

            .export-data-footer {
                position: absolute;
                z-index: 999;
                bottom: 14px;
                right: 20px;
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                justify-content: end;

                p {
                    margin: 0;
                    font-size: 0.85rem;
                    font-weight: 500;
                    color: #333335;
                }

                .btn {
                    padding: 7px 16px;
                    max-width: 156px;
                    width: 100%;
                    border-radius: 4px;
                    font-size: 0.8rem;

                    svg {
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
            }

            .customer-review-add-row {
                background-color: #f8f9fa;
                width: 99%;
                margin: 0 auto;
                margin-top: 25px;
                padding: 1rem;
                border-radius: 12px;

                .action-col {
                    a {
                        background-color: #e9f0f7;
                        color: #fefefe;
                        height: 26px;
                        width: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        font-size: 11px;
                        transition-duration: 0.2s;
                        cursor: pointer;

                        &.delete {
                            background-color: #f66060;
                        }

                        &.edit {
                            background-color: #5ad66b;
                        }

                        svg {
                            font-size: 23px;
                        }

                        &.edit:hover {
                            color: #2d77ed;
                            background-color: #cfe3fc;
                        }

                        &.delete:hover {
                            color: #ef4444;
                            background-color: #fee2e2;
                        }

                        &.view:hover {
                            color: #2db33d;
                            background-color: #d0fccf;
                        }
                    }
                }
            }
        }
    }
}

.ant-dropdown-placement-bottomLeft {
    inset: 62px auto auto 1234.22px !important;
}


.modal {
    .modal-header {
        padding: 12px 27px;
        background-color: #fff9f0;
        color: #ffa000;

        .modal-title {
            color: #ffa000;
            font-size: 1.1rem;
        }
    }

    .modal-body {
        padding: 15px 27px;
    }

    .modal-footer {
        text-align: center;
        padding: 18px 18px;
        margin: 0;
    }

    .field-emp-modal {
        label {
            font-weight: 400;
            color: #8d8d8d;
            font-size: 0.95rem;
            font-family: "Inter", sans-serif;

            p {
                color: #000;
                font-weight: 500;
                max-width: 260px;
            }
        }

        .col-md-6 {
            word-wrap: break-word;
        }
    }
}

.widget-rounded-circle {
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    border: 0;

    .card-body {
        padding: 1.5rem;

        .avatar-lg {
            height: 4.5rem;
            width: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &.bg-soft-primary {
                background-color: #6658dd40 !important;

                .dashboard-icon {
                    color: #6658dd;
                }
            }

            &.bg-soft-success {
                background-color: #1abc9c40 !important;
                border-color: #1abc9c !important;

                .dashboard-icon {
                    color: #1abc9c;
                }
            }

            &.bg-soft-warning {
                border-color: #f7b84b !important;
                background-color: #f7b84b40 !important;

                .dashboard-icon {
                    color: #f7b84b;
                }
            }

            &.bg-info-soft {
                background-color: #7eff523d;
                border-color: #00d220 !important;

                .dashboard-icon {
                    color: #00d220;
                }
            }

            &.bg-warning-soft {
                background-color: #ff52523d;
                border-color: #ff0000 !important;

                .dashboard-icon {
                    color: #ff0000;
                }
            }

            &.bg-blue-soft {
                background-color: rgb(150 215 255);
                border-color: #058eda !important;

                .dashboard-icon {
                    color: #1284cc;
                }
            }

            .anticon {
                font-size: 22px;
                color: #0d6efd;
            }

            svg {
                font-size: 1.5rem;
            }
        }
    }

}

.breadcrumb-main {
    font-size: 13px;
    min-height: 35.5px;
    display: flex;
    align-items: center;

    ol {
        &.breadcrumb {
            margin-bottom: 0;

            li {
                &.active {
                    font-weight: 600;
                    color: #000;
                }
            }
        }
    }
}

.assignee-details-accordion {
    .accordion-item {
        border: 1px solid #fcbb55;

        .accordion-header {
            .accordion-button {
                padding: 20px 23px;
                margin: 0;
                background: rgb(255 248 235);
                font-size: 16px;
                font-weight: 500;
                color: #1e293b;
                letter-spacing: 0.5px;
                border: none;
                border-radius: 8px;
                position: relative;
                border: 0 !important;

                &:focus {
                    box-shadow: 0 0 0 0.11rem #4c546890;
                }
            }

            &.accordion-nocollapse {
                .accordion-button {
                    pointer-events: none;

                    &::after {
                        display: none;
                    }
                }

            }
        }

        .accordion-collapse {
            background: #f8f9fa;
            border-radius: 8px;
            margin-top: -12px;

            .accordion-body {}
        }
    }
}

.pagination {
    .page-item {
        .page-link {
            font-size: 13px;
            color: #000000;
        }

        &.active {
            color: #e29225;
            background-color: #fff9f0;
            border-color: #ffa100;
            font-weight: 500;

            .page-link {
                color: #e29225;
                background-color: #fff9f0;
                border-color: #ffa100;
                font-weight: 500;
            }
        }
    }
}

.toast {
    position: relative;
    border-radius: 6px;

    .toast-header {
        padding: 0;
        background: none;
        border: 0;

        img,
        strong,
        small {
            display: none;
        }

        .btn-close {
            position: absolute;
            margin: 0;
            right: 3px;
            top: 2px;
            display: none;
        }
    }

    .toast-body {
        font-weight: 400;
        font-family: "Inter", sans-serif;
        border-radius: 12px;
        color: #000000;
        margin: 5px 0;
        padding-left: 20px;
    }

    &.bg-success-light {
        border-color: #a3cfbb !important;
        background-color: #d1e7dd !important;
    }

    &.bg-danger-light {
        border-color: #f1aeb5 !important;
        background-color: #f8d7da !important;
    }

    &.bg-warning-light {
        border-color: #f1aeb5 !important;
        background-color: #f8d7da !important;
    }
}

.step-2 {
    .row {
        .col-md-12 {
            &:last-child {
                &.select {
                    margin-bottom: 100px;
                }
            }
        }
    }
}

.divider {
    border: 1px dotted #c3c3c3;
    padding: 0 1px;
    height: 2px;
    margin: 5px 0;
}

.assign-status-col {
    p {
        font-size: 0.8rem;
        padding: 5px 10px;
        border-radius: 6px;
    }

    .assignStatus-NotScheduled {
        color: #d40202;
        background-color: #ad78782e;
        width: max-content;
    }

    .assignStatus-walkin {
        color: rgb(12, 65, 1);
        background-color: #7ead782e;
        width: max-content;
    }

    .scheduled-text {
        font-weight: 600;

        span {
            font-weight: 400;
        }
    }
}

.draggable-table__drag {
    font-size: .95em;
    font-weight: lighter;
    text-transform: capitalize;
    position: absolute;
    width: 100%;
    text-indent: 50px;
    border: 1px solid #f1f1f1;
    z-index: 10;
    cursor: grabbing;
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);

    opacity: 1;
}

.rearrange-btn {
    background-color: #4d556a;
    border: none;
    outline: none;

    &:hover,
    &:active {
        background-color: #535c72 !important;
        box-shadow: none;
    }
}

.qr-modal-container {
    .qr-modal {
        h2 {
            font-size: 24px;
            font-weight: 700;
        }
    }

    .qr-download-btn {
        background-color: #00d220;

        &:hover {
            background-color: #16ce32;
        }
    }

    .qr-close-btn {
        background-color: #4d556a;

        &:hover {
            background-color: #52596b;
        }
    }
}

.search-btn {
    white-space: nowrap;
}

// .search-type {
//     max-width: 102px;
//     width: 100%;
// }

.form-check-label {
    font-size: 0.85rem;
}

.count-wrapper {
    font-size: 0.85rem;
    white-space: nowrap;
    label {
        font-weight: 500;
    }
}

.dashboard-wrapper {
    .dashboard-card {
        .dashboard-card-body {
            .upload-box-main {
                h4 {
                    color: #454cad;
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 1;
                    margin-bottom: 9px;
                    text-align: center;
                }
                p {
                    align-items: center;
                    color: #5f6982;
                    display: flex;
                    font-size: 16px;
                    gap: 10px;
                    justify-content: center;
                    text-align: center;
                }
                .uploader {
                    clear: both;
                    display: block;
                    margin: 0 auto;
                    max-width: 650px;
                    width: 100%;

                    .image-upload {
                        background: #fff;
                        border: 3px solid #eee;
                        border-radius: 7px;
                        clear: both;
                        float: left;
                        padding: 2rem 1.5rem;
                        text-align: center;
                        transition: all .2s ease;
                        width: 100%;

                        .inside-upload-box {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            gap: 7px;
                            text-align: center;

                            .icon svg {
                                color: #454cad;
                                font-size: 65px;
                                width: 83px;
                            }

                            input {
                                background: #f8f9fa;
                                padding: 8px 14px;
                                width: 107px;
                            }
                        }
                    }
                }
                .error-msg {
                    color: #e42020 !important;
                    font-size: 14px !important;
                    margin: 10px;
                    position: absolute;
                }
                .select-file {
                    color: #e42020;
                    display: block;
                }
                .form-check {
                    display: flex;
                    align-items: end;
                    justify-content: center;
                    gap: 6px;
                }
            }
            .footer-btn-upload {
                align-items: center;
                display: flex;
                justify-content: center;
                text-align: center;
            }
        }
    }
}

.list-status {
    display: flex;
    align-items: center;

    .badge {
        background-color: #0035c1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 24px;
    }
}